<template>
	<div class="notice-page">
		<div class="search-module mb20">
			<a-input-search v-model="searchInfo" placeholder="请输入标题" enter-button="搜索" size="large" :loading="searchIng"
				@search="onSearch" />
		</div>
		<div class="control-box flex flex-end mb20">
			<a-button type="primary" @click="toAdd">新增</a-button>
		</div>
		<div class="table-box">
			<a-table :rowKey="
				(item, index) => {
					return index;
				}
			" :loading="dataLoading" :columns="columns" :data-source="list"
				:pagination="{ hideOnSinglePage: true, current: page.current, total: page.total }" :bordered="true"
				@change="artlistChange">
				<template slot="is_read" slot-scope="index, item">
					<a-button size="small" type="link" @click="showUserModal(2, item.airticle_id)">{{ item.is_read + "/"
					+ item.count }}</a-button>
				</template>
				<template slot="is_un_read" slot-scope="index, item">
					<a-button size="small" type="link" @click="showUserModal(3, item.airticle_id)">{{ item.is_un_read +
					"/" + item.count }}</a-button>
				</template>
				<template slot="operation" slot-scope="index, item">
					<div class="control-box">
						<a-button size="small" type="primary" @click="showSendModal(item)">发送</a-button>
						<a-button size="small" type="primary" @click="toEdit(item.airticle_id)"
							:disabled="item.count > 0">编辑</a-button>
						<a-button size="small" type="primary" @click="toView(item.airticle_id)">查看</a-button>
						<a-popconfirm title="确认复制该通知？" ok-text="是" cancel-text="否"
							@confirm="copyNotice(item.airticle_id)">
							<a-button size="small" type="danger">复制</a-button>
						</a-popconfirm>
						<a-popconfirm title="确认删除该通知？" :disabled="item.count > 0" ok-text="是" cancel-text="否"
							@confirm="deleteNotice(item.airticle_id)">
							<a-button :disabled="item.count > 0" size="small" type="danger">删除</a-button>
						</a-popconfirm>

					</div>
				</template>
			</a-table>
		</div>

		<a-modal v-model="sendShow" title="操作" :confirmLoading="sendIng" :cancelText="'取消'" :okText="'提交'"
			@ok="sendMsg">
			<a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="公告标题:" prop="is_wd">
				<div>{{ sendTitle }}</div>
			</a-form-model-item>
			<a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="发送方式:" prop="is_wd">
				<a-radio-group :options="sendOption" @change="typeChange" v-model="sendParam.type" />
			</a-form-model-item>
			<template v-if="sendParam.type != 1">
				<a-form-model-item v-if="sendParam.type == 2" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }"
					label="发送对象:" prop="is_wd">
					<a-select show-search placeholder="姓名" :value="perId" style="width: 100%"
						:default-active-first-option="false" :filter-option="false"
						:not-found-content="fetching ? undefined : null" @search="findUser" @change="setUser">
						<a-spin v-if="fetching" slot="notFoundContent" size="small" />
						<a-select-option :value="item.customer_id" v-for="(item, index) in seachUserData" :key="index">
							{{ item.name }}</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item v-if="sendParam.type == 3" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }"
					label="批量发送:" prop="is_wd">
					<!-- <a-input-search placeholder="文件名称" enter-button @search="findUser" /> -->
					<a-button @click="chooseFile">
						<a-icon type="upload" />
						{{ sendParam.file.name ? sendParam.file.name : '选择文件' }}
					</a-button>
					<a-button v-if="sendParam.type == 3" type="link" @click="downMb">下载模板</a-button>
				</a-form-model-item>

			</template>
			<a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="短信通知:">
				<a-switch v-model="sendParam.is_message"></a-switch>

				<div id="" style="line-height: 1.6">
					本消息将通过公众号消息同步推送。<br>
					短信同步推送【可选】(短信服务费由第三方收取，请酌情发送)
				</div>
			</a-form-model-item>
		</a-modal>

		<input type="file" name="" id="fileEle" value="" style="opacity: 0;position: fixed;pointer-events: none;" />

		<a-modal v-model="userModalShow" :title="(
		  userParams.type == 2
		    ? '已读'
		    : userParams.type == 3
		    ? '未读'
		    : '拒绝') + '用户'
		" :cancelText="'取消'" :okText="'全部导出'" @ok="exportExcel" class="user-ant-modal">
			<a-table :rowKey="
			  (item, index) => {
			    return index;
			  }
			" :loading="userLoading" :columns="userColumns" :data-source="userList" :pagination="{
			  hideOnSinglePage: true,
			  current: userpage.current,
			  total: userpage.total,
			}" @change="userlistChange" :bordered="true"></a-table>
		</a-modal>
	</div>
</template>

<script>
import { pro_api,domain } from "@/siteInfo.js";
import axios from 'axios';
let columns = [
	{
		title: 'ID',
		key: 'id',
		dataIndex: 'id'
	},
	{
		title: '标题',
		key: 'title',
		dataIndex: 'title'
	},
	{
		title: '排序',
		key: 'sort',
		dataIndex: 'sort'
	},
	{
		title: '创建时间',
		key: 'create_at_str',
		dataIndex: 'create_at_str'
	},
	{
		title: '状态',
		customRender: (text, record, index) => `${record.count <= 0 ? '未发布' : '已发布'}`
	},
	{
		title: '已读/总人数',
		scopedSlots: { customRender: "is_read" },
		// customRender: (text, record, index) => `${record.is_read}/${record.count}`
	},
	{
		title: '未读/总人数',
		scopedSlots: { customRender: "is_un_read" },
		// customRender: (text, record, index) => `${record.is_un_read}/${record.count}`
	},
	{
		title: '操作',
		dataIndex: 'operation',
		width: '301px',
		scopedSlots: { customRender: 'operation' }
	}
];

let sendOption = [
	{
		label: '全部用户',
		value: 1
	},
	{
		label: '指定发送',
		value: 2
	},
	{
		label: '批量发送',
		value: 3
	}
];
let userColumns = [
	{
		title: "ID",
		dataIndex: "id",
		key: "id",
	},
	{
		title: "姓名",
		dataIndex: "name",
		key: "name",
		width: 84
	},
	{
		title: "手机号",
		dataIndex: "phone",
		key: "phone",
	},
	{
		title: "户籍地",
		customRender: (text, item, index) =>
			`${item.domicile_pro +
			item.domicile_city +
			item.domicile_county +
			item.domicile_street +
			item.domicile_community +
			item.domicile_address}`,
	},
];
export default {
	data() {
		return {
			userModalShow: false,
			searchInfo: '',
			searchIng: false,
			columns: columns,
			dataLoading: false,
			list: [],
			page: {
				current: 1,
				total: 0
			},
			userpage: {
				current: 1,
				total: 0
			},
			sendOption: sendOption,
			sendShow: false,
			sendIng: false,
			sendParam: {
				type: 1,
				customer_id: '',
				is_message: false,
				file :{}
			},
			userParams: {
				type: null,
				airticle_id: null,
			},
			seachUserData: [],
			fetching: false,
			fileEle: null,
			sendTitle: '',
			userLoading: false,
			userColumns: userColumns,
			userList: [],
		};
	},
	computed: {
		token() {
			return this.$store.state.user.token;
		},
		perId() {
			if (this.sendParam.customer_id == '') {
				return [];
			} else {
				return [this.sendParam.customer_id];
			}
		}
	},
	created() { },
	mounted() {
		let t = this;
		this.fileEle = document.getElementById('fileEle');
		this.fileEle.addEventListener('change', function (e) {
			console.log(e.target.files[0]);
			console.log('gaibianle');
			t.sendParam.file = e.target.files[0];
		});
		// this.sendShow = true

		this.getData();
		t.findUser('');
	},
	methods: {
		userlistChange(e) {
			this.userpage.current = e.current;
			this.findUser()
		},
		artlistChange(e) {
			this.page.current = e.current;
			this.getData()
		},
		onSearch() {
			this.getData()
		},
		downMb(){
			window.open(domain.a + '/static/excel/sendToCustomer.xls');
		},
		exportExcel() {
			let t = this;
			console.log(pro_api);
			if (t.userList.length == 0) {
				return t.$message.warn('还没有用户')
			}
			window.open(
				pro_api.a +
				"typeCusTzExport?type=" +
				t.userParams.type +
				"&airticle_id=" +
				t.userParams.airticle_id
			)
		},
		typeChange(e) {
			console.log(e);
			let val = e.target.value;
			if (val == 1) {
				this.sendParam.customer_id = '';
				this.sendParam.file = '';
			}
			if (val == 2) {
				this.sendParam.file = '';
			}
			if (val == 3) {
				this.sendParam.customer_id = '';
			}
		},
		getData() {
			let t = this;
			t.dataLoading = true;
			t.$get({
				port: 'a',
				url: 'airticleList',
				data: {
					type: 3,
					page: t.page.current,
					searchInfo: t.searchInfo
				}
			})
				.then(res => {
					t.dataLoading = false;
					let { code, data, msg } = res;
					if (code == 0) {
						for (let i = 0; i < data.list.length; i++) {
							data.list[i].content = data.list[i].content.split(',');
						}
						t.list = data.list;
						t.page.total = data.total;
					} else {
						t.$message.error(msg, 1.5);
					}
				})
				.catch(err => {
					t.dataLoading = false;
				});
		},
		showSendModal(item) {
			this.sendParam.airticle_id = item.airticle_id;
			this.sendTitle = item.title;
			this.sendShow = true;
		},
		sendMsg() {
			let t = this;
			if (t.sendParam.type == 2) {
				if (!t.sendParam.customer_id) {
					return t.$message.error('请选择用户', 1.5);
				}
			}
			if (t.sendParam.type == 3) {
				if (!t.sendParam.file) {
					return t.$message.error('请先选择文件', 1.5);
				}
			}

			var xhr = new XMLHttpRequest();
			xhr.open('post', 'https://jcapi.mtree.cn/api/admin/sendtongzhi');
			var formData = new FormData();
			formData.append('airticle_id', t.sendParam.airticle_id);
			formData.append('customer_id', t.sendParam.customer_id);
			formData.append('is_message', t.sendParam.is_message);
			formData.append('type', t.sendParam.type);
			formData.append('file', t.sendParam.file);
			formData.append('token', t.token);
			xhr.send(formData);
			xhr.onload = function (e) {
				var res = JSON.parse(xhr.response);
				console.log(res);
				let { data, code, msg } = res;
				if (code == 0) {
					t.$message.success(msg, 1.5);
					t.sendShow = false
				} else {
					t.$message.error(msg, 1.5);
				}
				console.log(data);
			};
			// console.log(formData)
			// t.$post({
			// 	port: 'a',
			// 	url: 'sendtongzhi',
			// 	data: formData
			// }).then(res => {
			// 	let { code, data, msg } = res
			// 	if (code == 0) {
			// 		t.$message.success(msg, 1.5)
			// 		t.$router.go(-1)
			// 	} else {
			// 		t.$message.error(msg, 1.5)
			// 	}
			// }).catch(err => {

			// })
		},
		showUserModal(type, airticle_id) {
			let t = this;
			t.userModalShow = true;
			t.userList = [];
			t.userParams = {
				type,
				airticle_id,
			};
			t.userLoading = true;
			t.$get({
				port: "a",
				url: "typeCusTzList",
				data: t.userParams,
			}).then((res) => {
				t.userLoading = false;
				let { code, data, msg } = res;
				if (code == 0) {
					this.userList = data;
				} else {
					t.$message.error(msg, 1.5);
				}
			})
				.catch((err) => {
					t.userLoading = false;
				});
		},
		findUser(value) {
			console.log(value);
			let t = this;
			if (t.fetching) {
				return;
			}
			t.fetching = true;
			t.$get({
				port: 'a',
				url: 'customerlist',
				data: {
					searchInfo: value,
					page: 1,
					limit: 1000000
				}
			})
				.then(res => {
					t.fetching = false;
					let { code, data, msg } = res;
					if (code == 0) {
						t.seachUserData = data.list;
						t.userpage.total = data.total;
					} else {
						t.$message.error(msg, 1.5);
					}
				})
				.catch(err => {
					t.fetching = false;
				});
		},
		setUser(value) {
			console.log(value);
			this.sendParam.customer_id = value;
		},
		toAdd() {
			this.$router.push({ path: '/notice/addNotice' });
		},
		toEdit(id) {
			console.log(id);
			this.$router.push({ path: '/notice/addNotice', query: { airticle_id: id } });
		},
		toView(id) {
			this.$router.push({ path: '/notice/addNotice', query: { airticle_id: id, isView: true } });
		},
		chooseFile() {
			console.log(23123);
			this.fileEle.click();
		},
		deleteNotice(id) {
			let t = this;
			t.$get({
				port: 'a',
				url: 'airticleDel',
				data: { airticle_id: id }
			})
				.then(res => {
					let { code, data, msg } = res;
					if (code == 0) {
						t.$message.success(msg, 1.5);
						t.getData();
					} else {
						t.$message.error(msg, 1.5);
					}
				})
				.catch(err => { });
		},
		copyNotice(id) {
			let t = this;
			t.$get({
				port: 'a',
				url: 'airticleCopy',
				data: { airticle_id: id }
			})
				.then(res => {
					let { code, data, msg } = res;
					if (code == 0) {
						t.$message.success(msg, 1.5);
						t.getData();
					} else {
						t.$message.error(msg, 1.5);
					}
				})
				.catch(err => { });
		}
	}
};
</script>

<style lang="less">
.user-ant-modal {
	width: 700px;

	.ant-modal {
		width: 700px !important;
	}
}
</style>
